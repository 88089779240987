import React from 'react';

const CookiePolicyData = () => {
    return(
        <>
        <section className="section">
            <div className="section-title aos-init aos-animate" data-aos="fade">
                <div className="container align-items-left">
                    <div className='mt-5'>
                        <h2 className='mb-5'>Cookie policy for sambhu.ie</h2>
                            <p className='mb-3'><span className='fw-bold'>Last updated:</span> 04th November 2024<br></br>
                            At sambhu.ie, I use cookies to enhance your experience while visiting the website. This cookie policy explains what cookies are, how I use them, the types of cookies I use, and your choices regarding cookie management.
                            </p>
                            <p className='mb-3'>
                            <span className='fw-bold'>What are cookies?</span><br></br>
                            Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help me make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.
                            </p>
                            <p className='mb-3'>
                            <span className='fw-bold'>How I use cookies?</span><br></br>
                            When you use and access our website, I may place a number of cookies files in your web browser. I use cookies for the following purposes:
                            </p>
                            <p className='mb-3'>
                            <span className='fw-bold'>Essential cookies:</span> I use cookies to remember information that changes the way the website behaves or looks, such as a user's language preference on our website.
                            </p>
                            <p className='mb-3'>
                            <span className='fw-bold'>Account-related cookies:</span> I use cookies to manage the signup process and general administration. These cookies will usually be deleted when you log out; however, in some cases, they may remain afterward to remember your site preferences when logged out.
                            </p>
                            <p className='mb-3'>
                            <span className='fw-bold'>Analytics cookies:</span> I use cookies to help me analyze how our visitors use the website and to monitor website performance. This helps me provide a high-quality experience by customizing our offering and quickly identifying and fixing any issues that arise.
                            </p>
                            <p className='mb-3'>
                            <span className='fw-bold'>Types of cookies I use</span><br></br>
                            <span className='fw-medium'>Session cookies:</span> These are temporary cookies that expire once you close your browser.<br></br>
                            <span className='fw-medium'>Persistent cookies:</span> These remain on your device for a set period or until you delete them.<br></br>
                            <span className='fw-medium'>Third-party cookies:</span> In addition to our own cookies, I may also use various third-party cookies to report usage statistics of the service and deliver advertisements on and through the service.
                            </p>
                            <p className='mb-3'>
                            <span className='fw-bold'>Your choices regarding cookies</span><br></br>
                            You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by clicking on the appropriate opt-in or opt-out buttons(Click the 'I understand' button to accept) provided in our cookie consent banner.
                            Additionally, most web browsers allow you to control cookies through their settings. You can set your browser to refuse cookies or delete certain cookies. However, please note that if you choose to refuse or delete cookies, this could affect the availability and functionality of our services.
                            Changes to This Cookie Policy
                            I may update our Cookie Policy from time to time. I will notify you of any changes by posting the new Cookie Policy on this page. You are advised to review this Cookie Policy periodically for any changes.
                            </p>
                            <p className='mb-3'>
                            <span className='fw-bold'>Contact Me</span><br></br>
                            If you have any questions about Cookie Policy, please contact me at hello@sambhu.ie or dayal2007@hotmail.com.
                            <br></br><br></br>-Or-<br></br><br></br>
                            <a href='/contact'><button className='btn btn-success'>Contact me <i className="bi bi-chevron-right"></i></button></a>
                            </p>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default CookiePolicyData;