import React from 'react';

const ImagesForGallery = [
    {
        original: 'assets/img/art/thara.jpg',
        thumbnail: 'assets/img/art/thara.jpg',
        description: 'Goddess Tara'
    },    
    {
        original: 'assets/img/art/guru.jpg',
        thumbnail: 'assets/img/art/guru.jpg',
        description: 'Guru the rider'
    },
    {
        original: 'assets/img/art/crown-of-thorns.jpg',
        thumbnail: 'assets/img/art/crown-of-thorns.jpg',
        description: 'Crown of thorns'
    },    
    {
        original: 'assets/img/art/ganesha.jpg',
        thumbnail: 'assets/img/art/ganesha.jpg',
        description: 'Lord ganesha'
    },
    {
        original: 'assets/img/art/my-earth-my-mother.jpg',
        thumbnail: 'assets/img/art/my-earth-my-mother.jpg',
        description: 'My earth my mother '
    },
    {
        original: 'assets/img/art/peacock-blue.jpg',
        thumbnail: 'assets/img/art/peacock-blue.jpg',
        description: 'Peacock blue'
    },
    {
        original: 'assets/img/art/the-day-after-the-wind.jpg',
        thumbnail: 'assets/img/art/the-day-after-the-wind.jpg',
        description: 'The day after the wind'
    },
    {
        original: 'assets/img/art/way-of-hope.jpg',
        thumbnail: 'assets/img/art/way-of-hope.jpg',
        description: 'Way of hope'
    },
    {
        original: 'assets/img/art/way-to-dreams.jpg',
        thumbnail: 'assets/img/art/way-to-dreams.jpg',
        description: 'Way to dreams'
    },
    // Add more images as needed
];

export default ImagesForGallery;