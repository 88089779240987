import React, { useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Preloader from '../components/Preloader';
import { useNavigate } from 'react-router-dom';

const ContactForm: React.FC = () => {

      const [isLoading, setIsLoading] = useState(false);
      const recaptchaRef:any = useRef();
      const initialValues = { name: '', email: '', message: '' };
      const navigate = useNavigate();
      const validationSchema = Yup.object({
          name: Yup.string().required('Name is required'),
          email: Yup.string().email('Invalid email format').required('Email is required'),
          message: Yup.string().min(8, 'Message must be at least 8 characters').required('Message is required'),
      });

      const API_URL = 'https://sambhu.ie/mail/';

      const handleSubmit = async(values:any) => {
        console.log(values);
        try {
        // Get the reCAPTCHA token
        const token = recaptchaRef.current.getValue();
        if (!token) {
          alert("You must confirm you are not a robot");
          return;
        }
        setIsLoading(true); // Show preloader
        const response = await axios.post(`${API_URL}`, {'data':values, 'token': token});
          //return response.data;
          if(response.data.success=='success')
          {
            setIsLoading(true); // cancel preloader
            navigate('/thankyou');
          }  
        } catch (error) {
            setIsLoading(false); // cancel preloader
            console.error('Error: Mail sending failed:', error);
            alert('Error: Mail sending failed! Try again');
            throw error;
        }
      };      

    return(

    <section id="contact" className="contact section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        <p>Drop me a line through the form below and I will get back to you soon</p>
      </div>
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-5">
            <div className="info-wrap">
              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h3>Address</h3>
                  <p>Apartment 308, Courtyard house, Dock road, Limerick</p>
                </div>
              </div>

              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h3>Email</h3>
                  <p><a href="mailto:hello@sambhu.ie">hello@sambhu.ie</a>, <a href="mailto:hello@sambhu.ie">dayal2007@hotmail.com</a>
                  </p>
                </div>
              </div>
              <iframe  data-aos="fade-up" width="100%" data-aos-delay="400" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9680.042026188245!2d-8.644568126904405!3d52.65978780356722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x485b5c6245a706c9%3A0x1ddaa9b6d4d48a05!2sMountkennet%2C%20Limerick%2C%20V94%20A580!5e0!3m2!1sen!2sie!4v1721389244201!5m2!1sen!2sie" loading="lazy"></iframe>
            </div>
          </div>
          <div className="col-lg-7">
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            <Form className="php-email-form">
              <div className="row gy-4">
                <div className="col-md-6">
                  <label htmlFor="name-field" className="pb-2">Your Name</label>
                  <Field type="text" name="name" className="form-control" />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email-field" className="pb-2">Your Email</label>
                  <Field type="text" name="email" className="form-control" />
                  <ErrorMessage name="email" component="div" className="error"  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="message-field" className="pb-2">Message</label>
                  <Field type="textarea" name="message" className="form-control" as="textarea" rows={4} cols={40} />
                  <ErrorMessage name="message" component="div" className="error"  />
                </div>
                <div className="col-md-12">
                <ReCAPTCHA
                  className="g-recaptcha"
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                />
                </div>
                <div className="col-md-12">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>
                  <button type="submit">I want a customized website</button>
                  {isLoading && <Preloader />} {<Preloader />}
                </div>
              </div>
            </Form>
            </Formik>
          </div>
        </div>
      </div>
    </section>
    );
}

export default ContactForm;