import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/css/main.css';

import Header from './components/Header';
import Footer from './components/Footer';
import ScrollTop from './components/ScrollTop';
import CookieConsenter from './components/CookieConsenter';

import Home from './Pages/Home';
import Contact from './Pages/Contact';
import Thankyou from './Pages/Thankyou';
import CookiePolicy from './Pages/CookiePolicy';
import NotFound from './Pages/NotFound';
import About from './Pages/About';
import Skills from './Pages/Skills';
import ArtGallery from './Pages/ArtGallery';

import Colab from './sections/Colab';

const App = () => {
  return (
    <Router>

        <Header></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/art-gallery" element={<ArtGallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all for 404 */}
        </Routes>
        <Footer></Footer>
        <ScrollTop></ScrollTop>
        <CookieConsenter></CookieConsenter>
      </Router>
  );
}

export default App;