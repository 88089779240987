import React, {useEffect} from 'react';
import CookiePolicyData from '../sections/CookiePolicyData';
import Preloader from '../components/Preloader';
import Colab from '../sections/Colab';

const CookiePolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top
      }, []);
       

    return(
        <main className="main">
            <Preloader></Preloader>
            <Colab></Colab>
            <CookiePolicyData></CookiePolicyData>
        </main>    
    );
}

export default CookiePolicy;