import React from 'react';
import ImageGallery from 'react-image-gallery';
import imagesForGallery from '../components/ImagesForGallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const ArtGallery = () => {
    return(
        <section id="about" className="about section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Welcome to my Art Gallery</h2>
          <p>
          Welcome to my personal art gallery, a space where creativity meets passion. Here, I invite you to explore my collection of artworks that reflect my journey as an artist and my love for nature, abstract forms and human emotions. Each piece is a window into my imagination, crafted with care and intention. <br></br> 
          Explore some of my acrylic paintings that showcase the versatility and beauty of this dynamic medium. Each piece in my gallery tells a story, reflecting the creativity and emotional depth.
          </p>
        </div>
  
        <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className='row'>
                
            <div className='col-md-5'>
                <div className="image-gallery-wrapper">
                    <ImageGallery items={imagesForGallery} autoPlay={true} showThumbnails={true} />
                </div>
            </div>
            <div className='col-md-7'>
            <h3>About me - the artist</h3>
            <p>I am Sambhu Dayal, an artist based in Limerick, Ireland. My artistic journey began at the age of 5. Over the years, I have developed a distinctive style that combines  vibrant colors, intricate details with mixed media. My work is deeply influenced by nature, personal experiences and cultural heritage, allowing me to express complex emotions and ideas.</p>
            <h3>My Artistic Process</h3>
            <p>Creating art is a deep personal experience for me. I work primarily with acrylics, and watercolors, finding joy in the process of experimentation and discovery. I believe that art should evoke feelings and provoke thought, which is why I strive to infuse each piece of art with layers of meaning.</p>
            
            <h3>Useful links</h3>
            <p>
                <ol>
                    <li className="current"><a href="/contact"><i className="bi bi-easel"></i> Book your art classes</a></li>
                    <li><a href="/contact"><i className="bi bi-filetype-html"></i> Build your website with me</a></li>
                    <li className="current"><a href="/contact"><i className="bi bi-code-square"></i> Learn web development</a></li>
                </ol>
            </p>
            </div>
            </div>
            
        </div>
      </section>
    );
}

export default ArtGallery;