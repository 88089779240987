import React from 'react';

const ThankyouMessage = () => {
    return(
        <>
        <section className="section pt-0 pb-0" id="home">
            <div className="page-title aos-init aos-animate" data-aos="fade">
                <div className="container align-items-left">
                    <div className='row mt-5'><h1>Your message has been successfully sent</h1></div>
                    <div className='row mt-5'><p>Thank you for reaching out!<br></br> I appreciate your time and will get back to you shortly</p></div>
                    <div className='row'>
                        <h2>Sambhu Dayal Bahuleyan<br></br></h2>
                        <p><a href='/'><button className='btn btn-success'>Go home <i className="bi bi-chevron-right"></i></button></a></p>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default ThankyouMessage;