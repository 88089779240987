// App.js
import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsenter = () => {
    return (
        <div className="container">
            {/* Other components and content */}
            
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="myAppCookieConsent"
                buttonClasses="btn btn-warning"
                expires={150}
            >
                <div className='row mb-0 mt-0'>
                <div className='col-lg-6 text-xl-end'><h2 className='text-white'><i className="bi bi-cookie"></i> Cookie Policy</h2></div>
                <div className='col-lg-6 col-12 pt-xl-2'>This website uses cookies to enhance the user experience.<a href="/cookie-policy" style={{ color: "#fff", textDecoration: "underline" }}> Learn more</a></div> 
                </div>
            </CookieConsent>
        </div>
    );
}

export default CookieConsenter;