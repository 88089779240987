import React, {useEffect} from 'react';
import ThankyouMessage from '../sections/ThankyouMessage';
import Preloader from '../components/Preloader';
import Colab from '../sections/Colab';

const Thankyou = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top
      }, []);
       

    return (
        <main className="main">
        <Preloader></Preloader>
        <Colab></Colab>
        <ThankyouMessage></ThankyouMessage>
        </main>
    );
}

export default Thankyou;